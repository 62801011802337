import React from 'react';

import Box from '@mui/material/Box';
import { GatsbyImage } from "gatsby-plugin-image"

export default function StaticImage(props) {
    const {
        data,
        size = '380px',
        round = false,
    } = props;

    return (
        <Box
            sx={{
                display: 'inline-block',
                borderRadius: round ? '100%' : '4px',
                '-moz-border-radius': round ? '100%': '4px',
                '-webkit-border-radius': round ? '100%' : '4px',
                overflow: 'hidden',
                width: { xs: '340px', sm: size },
                height: {xs: '340px', sm: size },
                marginTop: '20px',
                textAlign: 'center',
            }}
        >
            <GatsbyImage
                style={{
                    display: 'inline-block',
                    borderRadius: round ? '100%' : '4px',
                    '-moz-border-radius': round ? '100%': '4px',
                    '-webkit-border-radius': round ? '100%' : '4px',
                }}
                imgStyle={{
                    display: 'inline-block',
                    borderRadius: round ? '100%' : '4px',
                    '-moz-border-radius': round ? '100%': '4px',
                    '-webkit-border-radius': round ? '100%' : '4px',
                }}
                image={data}
            />
        </Box>
    );
}
