import React from 'react';

import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from "../components/layout"
import StaticImage from "../components/static-image"

const TeachersPage = () => {
    const data = useStaticQuery(graphql`
        query TeachersPage {
            allDatoCmsBio {
                nodes {
                    name
                    textNode {
                        childMarkdownRemark {
                            html
                        }
                    }
                    index
                    image {
                        gatsbyImageData(
                          width: 400
                          placeholder: BLURRED
                          forceBlurhash: false
                        )

                    }
                }
            }
        }
    `);

    const { allDatoCmsBio } = data;

    return (
        <Layout image={false} header={false}>
          <Grid
              container
              direction="column"
              rowSpacing={4}
              sx={{
                  marginTop: '0px',
                  backgroundColor: 'rgba(255, 255, 255, .8)',
              }}
          >
            {allDatoCmsBio.nodes.sort((a, b) => (a.index > b.index)).map(node => (
                <>
                  <Grid item xs={12}>
                      <StaticImage
                        size="248px"
                        data={node.image.gatsbyImageData}
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <Typography variant="h5">{node.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <p dangerouslySetInnerHTML={{ __html: node.textNode.childMarkdownRemark.html }}/>
                  </Grid>
                </>
            ))}
          </Grid>
        </Layout>
    );
}

export default TeachersPage;
